import React from 'react';
import "./form.css"
export class TranslateMeForm extends React.Component{


    render(){
  
      return(
        <form className="translateMeSubmitDocumentForm" onSubmit={this.props.onSubmit} >
          <h4>Make a translation request</h4>
          <ul>
            <li>
              <label htmlFor="source">Document</label>
              <input type="file" id="source" name = "source" onChange={ this.props.fileChange } />
            </li>
            <li>            
              <label htmlFor="sourcelanguage">Document's Current Language</label>
              <select type="file" id="sourcelanguage" defaultValue="en" name = "sourcelanguage" onChange={this.props.langChange} >
                <LanguageOptions languages={this.props.languages} />
              </select>
            </li>
            <li> 
              <label htmlFor="targetlanguage">Desired Document Language</label>
              <select type="file" id="targetlanguage" name = "targetlanguage" onChange={this.props.langChange}  >
                <LanguageOptions languages={this.props.languages} />
              </select>
              </li>
              <li>
                <input type="submit" value="Send Request" />
              </li>
          </ul>
        </form>
      );
    }
  }

  function LanguageOptions(props){

    if( props.languages ){
      return Object.keys(props.languages).map( (e,i) => {
        return ( <option value={e} key={i}>{ props.languages[e] }</option> );
      });
    }
    return "";
  }
