import React from 'react';
import './header.css';
export class TranslateMeHeader extends React.Component{
    render(){
      return(
        <div className="header">
          <h3>TranslateMe</h3>
          logged in as : {this.props.user.name}
          <button
            onClick={ ()=>{this.props.logout() } }>logout
          </button>
        </div>
      );
    }
  }