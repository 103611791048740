import React from 'react';
import './document.css';

export class TranslateMeDocument extends React.Component{

  render(){
    return (
    <tr className={"document " +this.props.documentStatus}>
      <td>{this.props.documentName}</td>
      <td>{ this.props.documentStatus}</td>
      <td>{this.props.documentLanguage}</td>
      <td> 
        { this.props.documentStatus==="active" ? <a href={this.props.documentLink } target="_blank" rel="noreferrer">Download</a> : "" }
        { this.props.data }
      </td>
    </tr>);
  }
}