import React from 'react';
import './login.css';
export class TranslateMeLogin extends React.Component {


    constructor(props){
      super(props);
      this.state ={
        userName : null,
        password : null,
        message : null,
        processing :false,
      }
    }

    handleSubmit(e){
      e.preventDefault();
      this.setState(
        { processing:true }
      );

      let msg = null;
      if( !(this.state.userName) ){
        msg = "You must supply a user name!";
      }

      if(!(msg) && !(this.state.password) ){
        msg = "You must supply a password!";
      }

      if(msg){
        this.setState({
          message:msg,
          processing : false
        });
      }
      else{
        this.handleCallback();
      }
    }

    handleCallback = () =>{
        const callbackHandler = async() =>{
          if( this.props.callback ){
            const response = await this.props.callback( this.state.userName , this.state.password );
            this.setState({
              processing:false,
              response : response
            });
          }
        };
       callbackHandler();
    }

    render(){
      return (
        <div className="loginBox">
          <form onSubmit={(e)=> this.handleSubmit(e) }>
          <p>Please Login:</p>
          <p className={ (this.state.message)?"error":"hidden"}>{this.state.message}</p>
          <input type="text" onChange={ e=> this.setState({ userName: e.target.value } )}/>
          <input type="password"onChange={ e=> this.setState({ password: e.target.value } ) } />
          <input type="submit" value={ this.state.processing?"working...":"login" }/>
          </form>
        </div>
      );
    }
  }